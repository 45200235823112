import React, { Fragment, useState,useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, QRCode, Typography, message,Space,Modal,Table ,Badge} from 'antd';
import { Link, Redirect,useHistory } from 'react-router-dom';
import moment from 'moment';
import Axios from 'axios';
import { url } from '../../config/global';
import { PrinterOutlined, QrcodeOutlined } from "@ant-design/icons";
import { keys } from 'underscore';
const { Title } = Typography
const { Content } = Layout

function GenerateHistory() {
  //const navigate = useNavigate()
  const history = useHistory()
  const [expended, setExpended] = useState()
  const [visibleTable, setVisibleTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false)
  const [countSelected, setCountSelected] = useState(0);
  const [dataSelected, setDataSelected] = useState([]);
  const [btnPrint, setBtnPrint] = useState(false);
  
  useEffect(() => {
    getData()
    }, [])


  if (!sessionStorage.getItem('token')) {
    return <Redirect to="/login" />
  }



  const getData = () => {
      setLoading(true)
      Axios.get(`${url}/report-generate2`, {
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
      }).then(res => {
        setDataTable(res.data.payload)
      }).catch(err => {
          if (err.response) {  
              message.error(err.response.data.message)
              if (err.response.status === 401) {
                  sessionStorage.removeItem('token')
              }
          } else {
              message.error('Backend not yet Started')
          }
      }).finally(() => {
          setLoading(false)
      })
    }
  
    function printSingleHandler (data){
      const dataSelected = [data]
      Axios.post(
        `${url}/print-label2`,
        {
          payload:  [{"key" : data._id}],
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          history.push('/printLabelMaterial', { state: { dataSelected }   })
        }else{
          message.error("Prosess Print Failed!");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error(err.message);
        }
      })
    }
    function printMultipleHandler() {
      Axios.post(
        `${url}/print-label2`,
        {
          payload: dataSelected,
          byinvoice: true,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          history.push('/printLabelMaterial', { state: { dataSelected } })
        }else{
          message.error("Prosess Print Failed!");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error(err.message);
        }
      });
    }
    const columns = [
      {
        title: 'Qrcode',
        dataIndex: 'qr_code',
        key: 'qr_code'
      },
      {
        title: 'PO Number',
        dataIndex: 'po_number',
        key: 'po_number'
      },
      {
        title: 'Part Number',
        dataIndex: 'part_number',
        key: 'part_number',
        render: text => <a>{text}</a>,
      },
      {
        title: 'Total Qty',
        key: 'total_qty',
        render: (record) =>  record.total_qty.toLocaleString()
      },
      {
        title: 'Generate Date',
        key: 'generate_date',        
        render: (record) =>  moment(record.generate_date).format("YYYY-MM-DD")
      },
      {
        title: 'Generate By',
        key: 'user_generate',
        render: (record) =>  record.user_generate?.full_name
      },
    ]
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setCountSelected(selectedRows)
        setDataSelected(selectedRows)
      }
    };

 
  
    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                      <Title level={4}>Generate Label Material History</Title>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                  <Col xs={24}>
                    <Table
                      columns={columns}
                      dataSource={dataTable}
                      bordered
                      rowSelection={rowSelection}
                      loading={loading}
                      expandable={{
                        expandedRowRender: function(record, index, indent, expanded){
                          const cols = [
                            {
                              title: 'Qrcode',
                              dataIndex: 'qrcode',
                              key: 'qrcode',
                            },
                            {
                              title: 'Quantity',
                              dataIndex: 'quantity_formatted',
                              key: 'quantity_formatted',
                            },
                            {
                              title: 'Print Status',
                              key: 'print_count',
                              render: (record) => record.print_count> 0 ? <Badge status="success" text={record.print_count + "x Printed"} /> :<Badge status="warning" text={"never printed"} /> ,
                            },
                            {
                              title: 'Print Time',
                              key: 'print_time',        
                              render: (record) =>  record.print_time!=null?moment(record.print_time).format("YYYY-MM-DD") : "-"
                            },
                            {
                              title: 'Print By',
                              key: 'print_by',
                              render: (record) =>  record.print_byuser?.full_name ?? "-"
                            },
                            {
                              title: 'Action',
                              render: (record) => 
                              <Button onClick={() => printSingleHandler(record)}  type="primary" shape="round" icon={<PrinterOutlined />} size="small">{record.print_count> 0 ?  "Re-Print" : "Print "}</Button>
                            },
                           
                          ];
                         
                          return <Table columns={cols} dataSource={record.detail_generate} pagination={false} />;
                        }
                      }}
                      title={() => 
                        <Row>
                          <Col span={4}>
                            <Button type="primary" onClick={printMultipleHandler} disabled={countSelected?.length > 0? false : true}>
                              Print  {countSelected?.length > 0 ? `${countSelected?.length} Label` : ''}
                            </Button>
                          </Col>
                          <Col span={16}>
                            <center><span> Generate Material Label</span></center>
                          </Col>
                          <Col span={4}></Col>
                        </Row>
                        }
                    />
                  </Col>
                </Row>
               
            </Content>
        </Fragment>
    )
}

export default GenerateHistory