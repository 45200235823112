import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message, Space, Tooltip} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, FileExcelOutlined, ReloadOutlined, UploadOutlined, CaretDownOutlined} from '@ant-design/icons'
import moment from 'moment';
import EditBeginning from './components/EditBeginning';
import fileDownload from 'js-file-download'
import DetailStockOpname from './components/DetailHistoryBeginning';


const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker

const HistoryBeginning = () => {
    let [editPermission, setEditPermission] = useState(false);
    const tableRef = useRef(null);
    const [data, setData] = useState({
        start_date: moment().startOf('month'),
        end_date: moment()
    })
    // const beginning = 200;
    const [warehouses, setWarehouses] = useState([]); // Use this for the table data
    const [isDisabled, setIsDisabled] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
  
    const handleSearch = (value) => {
      setKeyword(value);
      // sessionStorage.setItem("keyword", value);
      setPage(1);
  
      if (value === "") {
        getData();
      }
    };
    const [dateColumns, setDateColumns] = useState([]);
    const [yearNow, setYearNow] = useState(moment());
    const [isEdited, setIsEdited] = useState(false)
    const [loading, setLoading] = useState(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend'
    })
    const [item, setItem] = useState({
      part_no_erp : '',
      description: '',
      month:'',
      year:'',
      qty: '',
      remarks: '',
    });
    const [modalId, setModalId] = useState('');
    const [keyword, setKeyword] = useState('')
    const handleChangeDate = (value) => {
      if (value) {
        const [startDate, endDate] = value;
        setData({
          ...data,
          start_date: startDate,
          end_date: endDate
        });
  
  
      } else {
        setData({
          ...data,
          start_date: undefined,
          end_date: undefined
        });
        setDateColumns([]);
      }
    };
  
    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const showModal = (record) => {
      setModalId(record._id)
      setIsModalVisible(true);
    };
  
    const closeModal = () => {
      setIsModalVisible(false);
    };

    const okModal = async () => {
      setIsModalVisible(false);
      setLoading(true);
      setIsDisabled(true);
      await Axios.post(`${url}/warehouse-sto/edit-beginning`,{
        part_no_erp: item.part_no_erp,
        description: item.description,
        qty: item.qty,
        month: item.month,
        year: item.year,
        remarks: item.remarks,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
          setItem({
            ...item,
            part_no_erp: '',
            description: '',
            month:'',
            year:'',
            qty: '',
            remarks: '',
          });
      }).catch(err => {
        console.log(err.response)
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                message.error(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        getData(keyword, page, sort);
      })
    };
  
    const getData = () => {
      console.log("generate data");
        setLoading(true);
        setIsDisabled(true);
        Axios.get(`${url}/warehouse-sto/history-beginning`, {
            params: {
                keyword,
                columns: [
                    'part_no_erp',
                    'part_no_old',
                    'description',
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                year: moment(yearNow).format('YYYY'),
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
          let editBeginning = false;
          if(res.data.edit_beginning === 'true'){
            editBeginning = true;
          }
          console.log(res.data);
          setWarehouses(res.data)
          setEditPermission(editBeginning);
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
            setIsDisabled(false)
        })
    }

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };
    
    useEffect(() => {
        getData()
    }, [keyword, page, sort, yearNow])
  
    const baseColumns = [
      {
        title: 'NO',
        dataIndex: 'no',
        key: 'no',
        align: 'center',
        width:30,
        render: (text, record, index) => {
          return (pagination.current - 1) * pagination.pageSize + index + 1;
        }
      },
      {
        title: 'PART ERP',
        dataIndex: 'part_no_erp',
        key: 'part_no_erp',
        width:200,
        // fixed: 'left',
        align: 'center',
      },
      {
        title: 'PART NO',
        dataIndex: 'part_no_old',
        key: 'part_no_old',
        width:200,
        // fixed: 'left',
        align: 'center',
      },
      {
        title: 'DESCRIPTION',
        dataIndex: 'description',
        key: 'description',
        width:150,
        // fixed: 'left',
        align: 'center',
      },
      {
        title: 'YEAR',
        dataIndex: 'year',
        key: 'year',
        width:150,
        // fixed: 'left',
        align: 'center',
      },
      {
        title : 'ACTION',
        align : 'center',
        width : 50,
        render: (text,record,index) => {
          return (
            <Fragment>
              <Dropdown className="pointer" overlay={
                  <Menu>
                      <Menu.Item key="1" onClick={() => showModal(record)}>
                        Edit BEGINNING
                      </Menu.Item>
                  </Menu>
              } trigger={['click']}>
                  <span className="pointer" onClick={e => e.preventDefault()}>
                      <MoreOutlined />
                  </span>
              </Dropdown>
              {isModalVisible && modalId === record._id && (
                <EditBeginning record={record} isModalVisible={isModalVisible} okModal={okModal} closeModal={closeModal} item={item} setItem={setItem}  />
              )}
            </Fragment>
          );
        }
      },
    ];
  
    // const allColumns = baseColumns;
  
    const pagination = {
      pageSize: 10,
      current: page,
      total: warehouses.total,
      showSizeChanger: false,
      onChange: (page) => setPage(page),
    };

    const onChange = (date, dateString) => {
      setYearNow(date);
    };

    const formattedNumber = (value) => {
      return value.toLocaleString('id-ID')
    };
    



  
    if (!sessionStorage.getItem('token')) {
      return <Redirect to="/login" />
    }
  
    return (
       <Fragment>
        <Row>
          <Col xs={24}>
            <Card>
              <Title level={4}>History Beginning</Title>
              <Row type="flex" justify="left">
                <Breadcrumb>
                  <Breadcrumb.Item>History Beginning</Breadcrumb.Item>
                </Breadcrumb>
              </Row>
            </Card>
          </Col>
        </Row>
  
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button type="primary" onClick={getData} disabled={isDisabled}><ReloadOutlined />Refresh</Button>
                    <DatePicker value={yearNow} onChange={onChange} picker="year" disabled={isDisabled}/>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    defaultValue={sessionStorage.getItem('keyword')}
                    onSearch={(value) => handleSearch(value)}
                    disabled={isDisabled}
                  />
                </Col>
                <Col xs={24}>
                   <Table
                      columns={baseColumns}
                      dataSource={warehouses.data}
                      rowKey={(data) => data._id} /// Make sure this is an array
                      pagination={pagination}
                      loading={loading}
                      onChange={handleTableChange}
                      bordered
                      scroll={{ x: 1000 }}
                      expandable={{
                        expandedRowKeys: expandedRowKeys,
                        onExpand: handleExpand,
                        expandedRowRender: (record) => (
                            <DetailStockOpname
                                key={`detail-${record.original_id}`}
                                id={record.original_id}
                                rows={expandedRowKeys}
                                part_number={record.part_no_erp}
                                year={record.year}
                            />
                        ),
                      }}
                    />
                </Col>  
              </Row>
            </Card>
          </Col>
        </Row>
      </Fragment>
    )
  }

export default HistoryBeginning