import React, {useState, useEffect} from 'react';
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message,Select, Space, Form, Badge, Checkbox} from 'antd';
import moment from 'moment';
import Axios from 'axios';
import {url} from '../../../config/global'
const { Title } = Typography
const { Option } = Select;
const { Content } = Layout
const layout = {
  labelCol: {
      lg: 10,
      md: 10
  },
  wrapperCol: {
      lg: 14,
      md: 14
  },
}
const tailLayout = {
  wrapperCol: {
      offset: 8,
      span: 16,
  },
}

const EditBeginning = ({ record, isModalVisible, okModal, closeModal, item, setItem }) => {
    console.log(item, "item")
    const [formattedDate, setFormattedDate] = useState(moment().format('dddd DD MMM YYYY HH:mm:ss'));
    const [date, setDate] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
    const [itemDisabled, setItemDisabled] = useState(true);
    const [isLeaderDisabled, setIsLeaderDisabled] = useState(false);
    const [itemInvoice, setItemInvoice] = useState([])
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })
    const [keyword, setKeyword] = useState('')
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
        setFormattedDate(moment().format('dddd DD MMM YYYY HH:mm:ss'));
        setDate(moment().format('YYYY/MM/DD HH:mm:ss'));
        }, 1000);
  
        return () => clearInterval(interval); // Clear the interval on component unmount
    }, []);
    const handleChangeScanModal = (name, value) => {
        setItem({
            ...item,
            [name]: value
        })
      }
    const [checkedItems, setCheckedItems] = useState([]);
      // Fungsi untuk check item
    const onItemCheck = (id) => {
        const filteredItems = data.find(item => item._id === id);
    
        // Toggle checked state of the item
        if (checkedItems.includes(id)) {
            // If item is already checked, uncheck it by removing it from the list
            setCheckedItems(checkedItems.filter(itemId => itemId !== id));
        } else {
            // If item is not checked, check it by adding it to the list
            setCheckedItems([...checkedItems, id]);
        }
    
        // Update the selected item data when checked
        if (!checkedItems.includes(id)) {
            setItem({
                ...item,
                part_no_erp:filteredItems.part_no_erp,
                description:filteredItems.description,
                qty:filteredItems.qty_balance,
                month:filteredItems.month,
                year:filteredItems.year,
                remarks:filteredItems.remarks,
            })
            setItemDisabled(false);
            setIsLeaderDisabled(false);
        } else {
            // If unchecked, you can clear the item or handle the logic accordingly
            setItem({
                ...item,
                part_no_erp: '',
                description: '',
                month:'',
                year:'',
                qty: '',
                remarks: '',
            });
            setItemDisabled(true);
            setIsLeaderDisabled(true);
        }
    };
    const getData = () => {
        setLoading(true);
        Axios.get(`${url}/warehouse-sto/history-beginning-get-detail`, {
            params: {
                keyword,
                part_no_erp:record.part_no_erp,
                year:record.year,
                perpage: 5,
                page,
                sort: sort.sort,
                order: sort.order,
                columns: ['month'],
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setData(res.data.data);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };
    const pagination = {
        pageSize: 5,
        current: page,
        total: itemInvoice.total,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
    };
    const baseColumns = [
        {
            title: '',
            dataIndex: 'no',
            key: 'no',
            align:'center',
            width:50,
            render: (text, record) => (
            <Checkbox
                value={record._id}
                onChange={() => onItemCheck(record._id)}
                checked={checkedItems.includes(record._id)} // Control checked state
            />
            ),
        },
        {
          title: 'PART ERP',
          dataIndex: 'part_no_erp',
          key: 'part_no_erp',
          width:200,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'PART NO',
          dataIndex: 'part_no_old',
          key: 'part_no_old',
          width:200,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'DESCRIPTION',
          dataIndex: 'description',
          key: 'description',
          width:150,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'MONTH',
          dataIndex: 'month',
          key: 'month',
          width:150,
          // fixed: 'left',
          align: 'center',
        },
        {
          title: 'YEAR',
          dataIndex: 'year',
          key: 'year',
          width:150,
          // fixed: 'left',
          align: 'center',
        },
        {
            title: 'QTY BALANCE',
            dataIndex: 'qty_balance',
            key: 'qty_balance',
            width:150,
            // fixed: 'left',
            align: 'center',
            render: (text, record, index) =>{
            return formattedNumber(record.qty_balance ?? 0);
            }
        },
        {
            title: 'STO BALANCE',
            dataIndex: 'sto_balance',
            key: 'sto_balance',
            width:150,
            // fixed: 'left',
            align: 'center',
            render: (text, record, index) =>{
            return formattedNumber(record.sto_balance ?? 0);
            }
        },
        {
            title: 'CREATED_AT',
            dataIndex: 'created_at',
            key: 'created_at',
            width:150,
            // fixed: 'left',
            align: 'center',
            render : (text,record,index) => {
                return moment(record.created_at).format('YYYY-MM-DD HH:mm:ss');
            }
        },
    ];
    const handleTableItemChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
        // showLeaderModal(checkLeader, pagination.current, sort);
        getData(record, pagination.current, sort)
    }
    useEffect(() => {
        if (isModalVisible && record && record.part_no_erp && !dataLoaded) {
            getData(record, page, sort);
        }
    }, [isModalVisible, record, page, sort, dataLoaded]);

    // Reset the `dataLoaded` flag when the modal closes
    useEffect(() => {
        if (!isModalVisible) {
            setDataLoaded(false);
        }
    }, [isModalVisible]);
    
    return (
        <Modal
        title="Edit Beginning"
        visible={isModalVisible}
        onOk={okModal}
        onCancel={closeModal}
        width={800}
        >
             <Row>
                <Col xs={24}>
                    <Table
                    columns={baseColumns}
                    dataSource={data}
                    rowKey={(data) => data._id} // Make sure this is an array
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableItemChange}
                    bordered
                    scroll={{ x: 500 }}
                    />
                </Col>  
            </Row>
            <Form 
                {...layout}
                name="control-hooks" style={{marginTop:'20px'}}>
                    <Row type="flex" gutter={[10, 10]} justify="space-around">
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Receipt Date"
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={date} disabled />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Part No ERP"
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={item.part_no_erp} disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" gutter={[10, 10]} justify="space-around">
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Description"
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={item.description} disabled />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Beginning"
                                required
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={item.qty} onChange={(e) => handleChangeScanModal('qty', e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" gutter={[10, 10]} justify="flex-start">
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Remarks"
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={item.remarks} onChange={(e) => handleChangeScanModal('remarks', e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Modal>
    );
};

export default EditBeginning;