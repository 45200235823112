import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, Button, message, Table, Input, Space, Dropdown, Menu } from 'antd'
import {Link,Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { UploadOutlined, CaretDownOutlined, ReloadOutlined, MoreOutlined} from '@ant-design/icons'
import moment from 'moment'
import fileDownload from 'js-file-download'
import DetailInvoice from './components/DetailInvoice';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout

function Invoice() {

    const [keyword, setKeyword] = useState('')
    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'descend'
    })
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedData, setSelectedData] = useState([]);

    const handleSearch = (value) => {
        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPage(1)

        if (value === '') {
            getData()
        }
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/invoice`, {
            params: {
                keyword,
                columns: [
                    'number',
                    'po_number',
                    'part_number'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setInvoices(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const downloadTemplate = () => {
        Axios.get(`${url}/invoice-download`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            responseType: 'blob'
        }).then(res => {
            fileDownload(res.data, 'template_invoice.xlsx')
        })
    }

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    // const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    //     setSelectedRows(newSelectedRowKeys);
    //     setSelectedData(selectedRows);
    // };

    // const rowSelection = {
    //     preserveSelectedRowKeys: false,
    //     selectedRowKeys: selectedRows,
    //     onChange: onSelectChange,
    //     getCheckboxProps: (record) => ({
    //         disabled: record.is_generate === 1,
    //         className: record.is_generate === 1 ? 'row-generate' : '',
    //     }),
    // };

    const handleReprintCheckSheet = async (no_invoice) => {
        setLoading(true);
        await Axios.post(`${url}/warehouse-checksheets/print-checksheet`, {
          no_invoice: no_invoice,
        }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
          responseType: 'blob',
        }).then(({ data }) => {
            setKeyword('');
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    message.error(err.response.data.errors)
                }
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
        //   getData();
        })
    }

    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };    
    
    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    const columns = [
        {
            title: 'Upload Date',
            dataIndex: 'created_at',
            sorter: true,
            render: (text, record, index) => {
                return moment(record.created_at).format('LL')
            }
        },
        {
            title: 'Invoice',
            dataIndex: 'number',
            sorter: true
        },
        {
            title: 'PO Number',
            dataIndex: 'po_number',
            sorter: true
        },
        // {
        //     title: 'Part Number',
        //     dataIndex: 'part_number',
        //     sorter: true
        // },
        // {
        //     title: 'Unit Price',
        //     dataIndex: 'unit_price',
        //     sorter: true
        // },
        // {
        //     title: 'SNP',
        //     dataIndex: 'snp',
        //     sorter: true
        // },
        {
            title: 'Total Packages',
            dataIndex: 'total_packages',
            sorter: true,
            render: (text, record, index) => {
                return formattedNumber(record.total_packages)
            }
        },
        {
            title : 'Total Qty',
            dataIndex : 'total_quantity',
            sorter: true,
            render: (text, record, index) => {
                return formattedNumber(record.total_quantity)
            }
        },
        {
            title : 'Source',
            dataIndex : 'sourcing',
            sorter: true
        },
        {
            title : 'Uom',
            dataIndex : 'uom',
            sorter : true
        },
        {
            title : 'Status Check',
            // dataIndex : 'status_check',
            sorter : true,
            render: (text, record, index) => {
                return (record.is_printed == 1) ? 'OK' : ''
            }
        },
        // {
        //     title : 'ACTION',
        //     align : 'center',
        //     render: (text,record,index) => {
        //       return (
        //         <Fragment>
        //           <Dropdown className="pointer" overlay={
        //               <Menu>
        //                   <Menu.Item key="1" onClick={() => handleReprintCheckSheet(record.number)}  disabled={record.is_printed !== 1}>
        //                     Reprint CheckSheet
        //                   </Menu.Item>
        //               </Menu>
        //           } trigger={['click']}>
        //               <span className="pointer" onClick={e => e.preventDefault()}>
        //                   <MoreOutlined />
        //               </span>
        //           </Dropdown>
        //         </Fragment>
        //       );
        //     }
        // },
        // {
        //     title: 'Quantity',
        //     dataIndex: 'quantity_formatted',
        //     sorter: true
        // },
        // {
        //     title: 'Amount',
        //     dataIndex: 'amount_formatted',
        //     sorter: true
        // },
    ]

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Upload Invoice</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Upload Invoice</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                <Col xs={24} md={12} lg={18}>
                                    <Space>
                                        <Link to="/invoice/upload">
                                            <Button type="primary"><UploadOutlined />Upload</Button>
                                        </Link>
                                            <Button type="primary" onClick={downloadTemplate}><CaretDownOutlined />Download Template</Button>
                                            <Button type="primary" onClick={getData}><ReloadOutlined />Refresh</Button>
                                    </Space>
                                    {/* <Upload {...uploadProps}>
                                        <Button>
                                            Upload Invoice <UploadOutlined />
                                        </Button>
                                    </Upload> */}
                                </Col>
                                <Col xs={24} md={12} lg={6} className="align-right">
                                    <Search
                                        placeholder="Search by Invoice / PO / Part"
                                        onSearch={(value) => handleSearch(value)}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Table
                                    columns={columns}
                                    dataSource={invoices.data}
                                    rowKey={(data) => data._id}
                                    pagination={{ pageSize: 10, current: page, total: invoices.total, showSizeChanger: false }}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    scroll={{ x: true }}
                                    // rowSelection={rowSelection}
                                    expandable={{
                                        expandedRowKeys: expandedRowKeys,
                                        onExpand: handleExpand,
                                        expandedRowRender: (record) => (
                                            <DetailInvoice
                                                key={`detail-${record._id}`}
                                                id={record._id}
                                                rows={expandedRowKeys}
                                                no_invoice={record.number}
                                                po_number={record.po_number}
                                            />
                                        ),
                                    }}
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default Invoice
