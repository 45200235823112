import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message, Space, Tooltip} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, FileExcelOutlined, ReloadOutlined, UploadOutlined, CaretDownOutlined} from '@ant-design/icons'
import moment from 'moment';
import ReceiveTooltip from './components/ReceiveTooltip';
import OutgoingTooltip from './components/OutgoingTooltip';
import EditBeginning from './components/EditBeginning';
import fileDownload from 'js-file-download'


const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker

const WarehouseInventory = () => {
    let [editPermission, setEditPermission] = useState(false);
    const tableRef = useRef(null);
    const [data, setData] = useState({
        start_date: moment().startOf('month'),
        end_date: moment()
    })
    // const beginning = 200;
    const [warehouses, setWarehouses] = useState([]); // Use this for the table data
    const [isDisabled, setIsDisabled] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
  
    const generateColumns = (startDate, endDate) => {
      const columns = [];
      let currentMonth = '';
      const dateColumns = [];
      const currentDate = moment(startDate);
      const end = moment(endDate);
  
      const dailyOnHand = {}; // Store the on_hand value for each date and part_number
  
      while (currentDate.isSameOrBefore(end)) {
          const monthYear = currentDate.format('MMM-YY');
  
          if (monthYear !== currentMonth) {
              if (currentMonth !== '') {
                  columns.push({
                      title: currentMonth,
                      children: dateColumns.slice(),
                  });
                  dateColumns.length = 0; // Clear date columns for the new month
              }
              currentMonth = monthYear;
          }
  
          const date = currentDate.format('YYYY-MM-DD'); // Date string in 'YYYY-MM-DD' format
          const key = currentDate.format('DD-MM-YYYY'); // Key for the column
          const previousDay = moment(currentDate).subtract(1, 'day').format('YYYY-MM-DD');
          dateColumns.push({
              title: currentDate.date().toString(),
              dataIndex: key,
              key: key,
              width: 50,
              align: 'center',
              // onHeaderCell: (record) => ({
              //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
              // }),
              // onCell: (record) => ({
              //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
              // }),
              render: (text, record) => {
                  const partNumber = record.part_number_internal;
                  const min = record.min_stock || 0;
                  const keyPart = `${partNumber}-${date}`;
  
                  // Determine if it's the first day of the data range
                  const isFirstDay = currentDate.isSame(startDate, 'day');

                  // Get the previous day's on_hand value if it exists
                  const previousOnHand = dailyOnHand[`${partNumber}-${previousDay}`];

                  // Use the record's beginning value on the first day or previous on_hand value if available
                  const beginning = isFirstDay
                      ? record.beginning
                      : (previousOnHand !== undefined ? previousOnHand : record.beginning);
  
                  const receiving = record.receivings
                      .filter(item => item.part_number === partNumber && item.date === date)
                      .reduce((sum, item) => sum + item.totalSum, 0);
                  
                  const dataReceiving = record.receivings
                      .filter(item => item.part_number === partNumber && item.date === date)
  
                  const outgoing = record.outgoings
                      .filter(item => item.part_number === partNumber && moment(parseInt(item.date.$date.$numberLong)).format('YYYY-MM-DD') === date)
                      .reduce((sum, item) => sum + item.totalSum, 0);

                  const dataOutgoing = record.outgoings
                      .filter(item => item.part_number === partNumber && moment(parseInt(item.date.$date.$numberLong)).format('YYYY-MM-DD') === date);
  
                  // Calculate on_hand for the current date using the appropriate beginning value
                  const on_hand = (beginning + receiving) - outgoing;
  
                  // Save the on_hand value for use in the next day's calculation
                  dailyOnHand[keyPart] = on_hand;
  
                  return (
                      <table border={0} style={{ padding: '0', textAlign:'center' }}>
                          <tbody>
                              <tr>
                                  <td style={{border:0, fontSize:14, cursor:receiving !== 0?'pointer':''}}>
                                    {receiving !== 0 ? (
                                      <ReceiveTooltip record={dataReceiving} receiving={receiving} />
                                    ) : (
                                      <span>{receiving}</span>
                                    )}
                                  </td>
                              </tr>
                              <tr>
                                  <td style={{border:0, fontSize:14, cursor:outgoing !== 0?'pointer':''}}>
                                  {outgoing !== 0 ? (
                                      <OutgoingTooltip record={dataOutgoing} date={date} receiving={outgoing} />
                                    ) : (
                                      <span>{outgoing}</span>
                                    )}
                                  </td>
                              </tr>
                              <tr>
                                  <td style={{border:0, fontSize:14, color: on_hand < min ? 'red' : 'black'}}>{on_hand}</td>
                              </tr>
                          </tbody>
                      </table>
                  );
              },
          });
  
          currentDate.add(1, 'day'); // Move to the next day
      }
      
  
      if (dateColumns.length > 0) {
          columns.push({
              title: currentMonth,
              children: dateColumns,
              // width: 100,
              align: 'center',
              // onHeaderCell: (record) => ({
              //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
              // }),
              // onCell: (record) => ({
              //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
              // }),
          });
      }
  
      return columns;
  };
    const handleSearch = (value) => {
      setKeyword(value);
      // sessionStorage.setItem("keyword", value);
      setPage(1);
  
      if (value === "") {
        getData();
      }
    };
    const [dateColumns, setDateColumns] = useState([]);
    const [isEdited, setIsEdited] = useState(false)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })
    const [item, setItem] = useState({
      part_no_erp : '',
      description: '',
      month:'',
      year:'',
      qty: '',
      remarks: '',
    });
    const [keyword, setKeyword] = useState('')
    const handleChangeDate = (value) => {
      if (value) {
        const [startDate, endDate] = value;
        setData({
          ...data,
          start_date: startDate,
          end_date: endDate
        });
  
        // Generate new columns based on the updated date range
        const newDateColumns = generateColumns(startDate, endDate);
        setDateColumns(newDateColumns);
  
      } else {
        setData({
          ...data,
          start_date: undefined,
          end_date: undefined
        });
        setDateColumns([]);
      }
    };
  
    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const showModal = (record) => {
      setItem({
        ...item,
        part_no_erp:record.part_number_internal,
        description:record.part_name,
        qty:record.beginning,
        month:record.beginning_month,
        year:record.beginning_year,
        remarks:record.beginning_remarks,
      })
      setIsModalVisible(true);
    };
  
    const closeModal = () => {
      setIsModalVisible(false);
    };

    const okModal = async () => {
      setIsModalVisible(false);
      setLoading(true);
      setIsDisabled(true);
      await Axios.post(`${url}/warehouse-sto/edit-beginning`,{
        part_no_erp: item.part_no_erp,
        description: item.description,
        qty: item.qty,
        month: item.month,
        year: item.year,
        remarks: item.remarks,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
          setItem({
            ...item,
            part_no_erp: '',
            description: '',
            month:'',
            year:'',
            qty: '',
            remarks: '',
          });
      }).catch(err => {
        console.log(err.response)
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                message.error(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        getData(keyword, page, sort);
      })
    };
  
    const getData = () => {
      console.log("generate data");
        setLoading(true);
        setIsDisabled(true);
        Axios.get(`${url}/warehouse-inventory`, {
            params: {
                keyword,
                columns: [
                    'part_number_internal',
                    'part_number_external',
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                start_date: moment(data.start_date).format("YYYY-MM-DD"),
                end_date: moment(data.end_date).format("YYYY-MM-DD")
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
          let editBeginning = false;
          if(res.data.edit_beginning === 'true'){
            editBeginning = true;
          }
          console.log(res.data);
          setWarehouses(res.data)
          setDateColumns(generateColumns(data.start_date, data.end_date));
          setEditPermission(editBeginning);
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
            setIsDisabled(false)
        })
    }
    
    useEffect(() => {
        getData()
    }, [keyword, page, sort, data.start_date, data.end_date])
  
    const baseColumns = [
      {
        title: 'NO',
        dataIndex: 'no',
        key: 'no',
        align: 'center',
        width:30,
        // fixed: 'left',
        // onHeaderCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        // onCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        render: (text, record, index) => {
          return (pagination.current - 1) * pagination.pageSize + index + 1;
        }
      },
      // {
      //   title: 'ADDRESS',
      //   dataIndex: 'address',
      //   key: 'address',
      //   width:100,
      //   fixed: 'left',
      //   align: 'center',
      // },
      // {
      //   title: 'DIV',
      //   dataIndex: 'div',
      //   key: 'div',
      //   width:100,
      //   fixed: 'left',
      //   align: 'center',
      // },
      {
        title: 'PART ERP',
        dataIndex: 'part_number_internal',
        key: 'part_number_internal',
        width:200,
        // fixed: 'left',
        align: 'center',
        // onHeaderCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        // onCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
      },
      {
        title: 'PART NO',
        dataIndex: 'part_number_external',
        key: 'part_number_external',
        width:200,
        // fixed: 'left',
        align: 'center',
        // onHeaderCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        // onCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
      },
      {
        title: 'DESCRIPTION',
        dataIndex: 'part_name',
        key: 'part_name',
        width:150,
        // fixed: 'left',
        align: 'center',
        // onHeaderCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        // onCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
      },
      {
        title: 'SNP',
        dataIndex: 'snp',
        key: 'snp',
        width:50,
        // fixed: 'left',
        align: 'center',
        // onHeaderCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        // onCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
      },
      {
        title: 'STANDARD',
        dataIndex: 'data',
        width: 200,
        // fixed: 'left',
        align: 'center',
        // onHeaderCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        // onCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        render: (text, record, index) => {
          return (
            <Fragment>
              <table border={0} style={{padding: '0', borderRight: 0}}>
                <tbody>
                  <tr>
                    <td style={{ paddingRight: '30px', border:0, fontSize:14 }}>MIN</td>
                    <td style={{border:0, fontSize:14}}>
                      { record.min_stock || '0' }
                      <Dropdown className="pointer" overlay={
                        <Menu>
                            <Menu.Item key="1">
                            <Link to={`/item-group/edit/${record._id}`}><EditOutlined /> Edit MIN / MAX</Link>
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <span className="pointer" onClick={e => e.preventDefault()}>
                            <MoreOutlined />
                        </span>
                    </Dropdown>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '30px' , border:0, fontSize:14  }}>MAX</td>
                    <td style={{border:0, fontSize:14}}>
                      { record.max_stock || '0'}
                      {/* <Dropdown className="pointer" overlay={
                          <Menu>
                              <Menu.Item key="1">
                                <Link to={`/item-group/edit/${record._id}`}><EditOutlined /> Edit MAX</Link>
                              </Menu.Item>
                          </Menu>
                      } trigger={['click']}>
                          <span className="pointer" onClick={e => e.preventDefault()}>
                              <MoreOutlined />
                          </span>
                      </Dropdown> */}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '30px' , border:0 , fontSize:14 }}>BEGINNING</td>
                    <td style={{border:0 , fontSize:14}}>
                    {record.beginning}
                    {record.beginning !== 0 && editPermission && (
                      <Fragment>
                      <Dropdown className="pointer" overlay={
                          <Menu>
                              <Menu.Item key="1" onClick={() => showModal(record)}>
                                Edit BEGINNING
                              </Menu.Item>
                          </Menu>
                      } trigger={['click']}>
                          <span className="pointer" onClick={e => e.preventDefault()}>
                              <MoreOutlined />
                          </span>
                      </Dropdown>
                      {isModalVisible && (
                        <EditBeginning record={record} isModalVisible={isModalVisible} okModal={okModal} closeModal={closeModal} item={item} setItem={setItem}  />
                      )}
                      </Fragment>
                    )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Fragment>
          )
        },
      },
      {
        title: 'CONTROL',
        dataIndex: 'control',
        key: 'control',
        width:150,
        // fixed: 'left',
        align: 'center',
        // onHeaderCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        // onCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        render: (text, record, index) => {
          return (<table border={0} style={{ padding: '0', width: '100px'}}>
            <tbody>
              <tr>
                <td style={{border:0, fontSize:14}}>Actual Receipt</td>
              </tr>
              <tr>
                <td style={{border:0, fontSize:14}}>Actual Out</td>
              </tr>
              <tr>
                <td style={{border:0, fontSize:14}}>On Hand</td>
              </tr>
            </tbody>
          </table>);
        },
      },
      {
        title: 'TOTAL',
        dataIndex: 'total',
        key: 'total',
        width:100,
        // fixed: 'left',
        align: 'center',
        // onHeaderCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        // onCell: (record) => ({
        //   style: { backgroundColor: '#003366', color:'#ffffff' }, // Background color for this cell
        // }),
        render: (text, record, index) => {
           const totalReceiving = record.receivings
            .reduce((sum, item) => sum + item.totalSum, 0);
           const totalOutgoing = record.outgoings
            .reduce((sum, item) => sum + item.totalSum, 0);
          let totalOnHand = (record.beginning + totalReceiving) - totalOutgoing;
          return (<table style={{ textAlign: 'center', fontSize:14 }}>
            <tbody>
              <tr>
                <td style={{border:0, fontSize:14}}>{ totalReceiving }</td>
              </tr>
              <tr>
                <td style={{border:0, fontSize:14}}>{ totalOutgoing }</td>
              </tr>
              <tr>
                <td style={{border:0, fontSize:14}}>{ totalOnHand }</td>
              </tr>
            </tbody>
          </table>);
        },
      },
    ];
  
    const memoizedColumns = useMemo(() => {
      return generateColumns(data.start_date, data.end_date);
  }, [data.start_date, data.end_date]);
    
    const allColumns = [...baseColumns, ...dateColumns];
    // const allColumns = baseColumns;
  
    const pagination = {
      pageSize: 10,
      current: page,
      total: warehouses.total,
      showSizeChanger: false,
      onChange: (page) => setPage(page),
    };

    const handlePrinted = async () => {
      setLoading(true);
      setIsDisabled(true);
      await Axios.post(`${url}/warehouse-inventory/print`, {
        keyword,
        columns: [
            'part_number_internal',
            'part_number_external',
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
        start_date: moment(data.start_date).format("YYYY-MM-DD"),
        end_date: moment(data.end_date).format("YYYY-MM-DD")
      }, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        },
        responseType: 'blob',
      }).then(({ data }) => {
          setKeyword('');
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
      }).catch(err => {
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                message.error(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false)
        setIsDisabled(false);
      })
    }

    const handleExportExcel = () => {
      setLoading(true);
      setIsDisabled(true);
      Axios.get(`${url}/warehouse-inventory/export-excel`, {
          params: {
            keyword,
            columns: [
                'part_number_internal',
                'part_number_external',
            ],
            perpage: 10,
            page,
            sort: sort.sort,
            order: sort.order,
            start_date: moment(data.start_date).format("YYYY-MM-DD"),
            end_date: moment(data.end_date).format("YYYY-MM-DD")
          },
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
          responseType: 'blob'
      }).then(res => {
          fileDownload(res.data, 'report_warehouse_inventory.xlsx')
      }).finally(() => {
        setLoading(false)
        setIsDisabled(false);
      })
    }
  
    if (!sessionStorage.getItem('token')) {
      return <Redirect to="/login" />
    }
  
    return (
       <Fragment>
        <Row>
          <Col xs={24}>
            <Card>
              <Title level={4}>Warehouse Inventory</Title>
              <Row type="flex" justify="left">
                <Breadcrumb>
                  <Breadcrumb.Item>Warehouse Inventory</Breadcrumb.Item>
                </Breadcrumb>
              </Row>
            </Card>
          </Col>
        </Row>
  
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button type="primary" onClick={getData} disabled={isDisabled}><ReloadOutlined />Refresh</Button>
                    <RangePicker
                      onChange={handleChangeDate}
                      value={[data.start_date, data.end_date]}
                      format="DD MMMM YYYY"
                      disabled={isDisabled}
                    />
                    {warehouses.total > 0 && (
                      <Button loading={loading} type="primary" onClick={handleExportExcel} style={{marginRight:10}} disabled={isDisabled}>
                          Export Excel <FileExcelOutlined />
                      </Button>
                    )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    defaultValue={sessionStorage.getItem('keyword')}
                    onSearch={(value) => handleSearch(value)}
                    disabled={isDisabled}
                  />
                </Col>
                <Col xs={24}>
                   <Table
                      columns={allColumns}
                      dataSource={warehouses.data}
                      rowKey={(data) => data._id} /// Make sure this is an array
                      pagination={pagination}
                      loading={loading}
                      onChange={handleTableChange}
                      bordered
                      scroll={{ x: 1000 }}
                    />
                </Col>  
              </Row>
              <Row>
                <Col xs={24} style={{ textAlign: 'right' }}>
 
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Fragment>
    )
  }

export default WarehouseInventory