import React, { useEffect } from 'react'
import "./Print.less";
import {  QRCode } from 'antd-v5';
import { useLocation } from 'react-router-dom';

import moment from "moment";
export default function Print() {
    const location = useLocation();
    //const arrayData = location.state.state?.dataSelected;
    
    const x = localStorage.getItem("dataPrint");
    const arrayData = JSON.parse(x)
    useEffect(() => {
        window.print()
    }, [])
    
   
  return (
    <div>
        { arrayData.length>0?
            arrayData.map(items => {
                if(items.detail_generate){ //multiple by invoice id
                    
                    if(items.detail_generate.length>0){
                    const comps = [];
                       for (let index = 0; index < items.detail_generate.length; index++) {
                        const element = items.detail_generate;
                        comps.push(
                                <div className="label" key={element[index]._id}>
                                    <table width="100%">
                                        <tbody >
                                            <tr  style={{lineHeight:"15px"}}>
                                                <td> <img src={require('../../assets/img/logo.png')}  style={{height:"10px",width:"50px"}}  /></td>
                                                <td></td>
                                                <td colSpan="2" style={{paddingLeft:"20px", fontSize:"12px",fontWeight:"bold"}}>PT. SANOH INDONESIA</td>
                                            </tr>
                                            <tr> 
                                                <td>PART ERP</td>
                                                <td>:</td>
                                                <td colSpan="2">{element[index].no_invoice}{element[index].part_number}</td>
                                            </tr>
                                            <tr>
                                                <td>PART NUMBER</td>
                                                <td>:</td>
                                                <td colSpan="2">{element[index].part_number}</td>
                                            </tr>
                                            <tr>
                                                <td>DESCRIPTION</td>
                                                <td>:</td>
                                                <td colSpan="2">{element[index].description}</td>
                                            </tr>
                                            <tr>
                                                <td>QUANTITY</td>
                                                <td>:</td>
                                                <td>{element[index].qty} {element[index].uom} T.Qty: {element[index].total_qty} {element[index].uom}</td>
                                                <td rowSpan="3">
                                                    <table className="status_receiving">
                                                        <tr>
                                                            <td style={{textAlign:"center", fontWeight:"bold"}}>{element[index].receiv_status==1? "OK" : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{marginLeft:"5px", display: "flex", justifyContent:"space-between", borderTop:"1px solid",borderBottom:"1px solid",fontSize:"5px"}}>{element[index].receiv_date}<small> &nbsp; {element[index].receiv_time}</small></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{textAlign:"center",fontSize:"7px"}}>{element[index].receiv_pic}</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>LOT NO</td>
                                                <td>:</td>
                                                <td>{element[index].number}</td>
                                            </tr>
                                            <tr>
                                                <td>SOURCHING</td>
                                                <td>:</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    <table  style={{width:"100%"}}>
                                                        <tr>
                                                            <td style={{width:"50%"}}>
                                                                <QRCode type="svg" bordered={false}  size={45} value={element[index].qrcode} />
                                                                {/* <label style={{fontSize:"4px"}}>{element[index].qrcode}</label> */}
                                                            </td>
                                                            <td style={{width:"25%"}}>
                                                                <div className="fifo"><div className="text_fifo"></div></div>
                                                            </td>
                                                            <td style={{marginTop:"8px", textAlign:"right", float:"right", verticalAlign:"bottom"}}>
                                                                <QRCode type="svg"  bordered={false}  size={35} value={element[index].po_number} />
                                                                <label style={{fontSize:"6px"}}>{element[index].po_number}</label>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                )
                        
                       }
                        return  comps;
                        
                    }else{
                        return ( <p>Tidak Ada Data</p>)
                    }
                }else{ //single print by qrcode id
                    return (
                    <div className="label" key={items.key}>
                        <table width="100%">
                            <tbody >
                                <tr  style={{lineHeight:"15px"}}>
                                    <td> <img src={require('../../assets/img/logo.png')}  style={{height:"10px",width:"50px"}}  /></td>
                                    <td></td>
                                    <td colSpan="2" style={{paddingLeft:"20px", fontSize:"12px",fontWeight:"bold"}}>PT. SANOH INDONESIA</td>
                                </tr>
                                <tr> 
                                    <td>PART ERP</td>
                                    <td>:</td>
                                    <td colSpan="2">{items.part_number}</td>
                                </tr>
                                <tr>
                                    <td>PART NUMBER</td>
                                    <td>:</td>
                                    <td colSpan="2">{items.part_number_external}</td>
                                </tr>
                                <tr>
                                    <td>DESCRIPTION</td>
                                    <td>:</td>
                                    <td colSpan="2">{items.part_name}</td>
                                </tr>
                                <tr>
                                    <td>QUANTITY</td>
                                    <td>:</td>
                                    <td>{items.stock_outgoing} {items.satuan} T.Qty: {items.total_qty} {items.satuan}</td>
                                    <td rowSpan="3">
                                        <table className="status_receiving">
                                            <tr>
                                                <td style={{textAlign:"center", fontWeight:"bold"}}>OK</td>
                                            </tr>
                                            <tr>
                                                <td style={{marginLeft:"5px", display: "flex", justifyContent:"space-between", borderTop:"1px solid",borderBottom:"1px solid",fontSize:"5px"}}>{moment(items.scan_date).format('D MMM Y')}<small> &nbsp; {items.scan_time}</small></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign:"center",fontSize:"7px"}}>{items.users?.full_name?? ""}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>LOT NO</td>
                                    <td>:</td>
                                    <td>{items.unique_code}</td>
                                </tr>
                                <tr>
                                    <td>SOURCHING</td>
                                    <td>:</td>
                                    <td>{items.sourcing}</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">
                                        <table  style={{width:"100%"}}>
                                            <tr>
                                                <td style={{width:"50%"}}>
                                                    <QRCode type="svg" bordered={false}  size={45} value={items.qr_code} />
                                                    {/* <label style={{fontSize:"4px"}}>{items.qrcode}</label> */}
                                                </td>
                                                <td style={{width:"25%"}}>
                                                    <div className="fifo"><div className="text_fifo"></div></div>
                                                </td>
                                                <td style={{marginTop:"8px", textAlign:"right", float:"right", verticalAlign:"bottom"}}>
                                                    <QRCode type="svg"  bordered={false}  size={35} value={items.no_invoice} />
                                                    <label style={{fontSize:"6px"}}>{items.no_invoice}</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    )
                }
            })
         : <p>Tidak Ada Data</p> 
        } 
    </div>
  )
  
}
