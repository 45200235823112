import React, {useState, useEffect} from 'react';
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message,Select, Space, Form, Badge, Checkbox} from 'antd';
import moment from 'moment';
import Axios from 'axios';
import {url} from '../../../config/global'
const { Title } = Typography
const { Option } = Select;
const { Content } = Layout
const layout = {
  labelCol: {
      lg: 10,
      md: 10
  },
  wrapperCol: {
      lg: 14,
      md: 14
  },
}
const tailLayout = {
  wrapperCol: {
      offset: 8,
      span: 16,
  },
}
const ChildComponent = ({ record, area, isModalVisible, okModal, closeModal, item, setItem }) => {
  const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })
    const [error, setError] = useState({
         qty_actual_receipt: ''
    })
    const [formattedDate, setFormattedDate] = useState(moment().format('dddd DD MMM YYYY HH:mm:ss'));
    const [date, setDate] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
    const [isOkDisabled, setIsOkDisabled] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false); // Flag to track if data is already loaded
    const [checkedItems, setCheckedItems] = useState([]);
    const [loading, setLoading] = useState(false)
    const [itemDisabled, setItemDisabled] = useState(true);
    const [isLeaderDisabled, setIsLeaderDisabled] = useState(true);
    const [itemInvoice, setItemInvoice] = useState([])
    const paginationItem = {
      pageSize: 5,
      current: page,
      total: itemInvoice.total,
      showSizeChanger: false,
      onChange: (page) => setPage(page),
    };

    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };

    const formattedPartStatus = (value) => {
        if(value == 'ok'){
            return 'OK';
        }else if (value == 'ng'){
            return 'NG';
        }else if (value == 'rework') {
            return 'REWORK';
        }
    };

    const formattedJenisMaterial = (value) => {
        if(value == 'material'){
            return 'Material';
        }else if (value == 'in_process'){
            return 'In Process';
        }else if (value == 'finish_process') {
            return 'Finish Process';
        }
    };
  
    const handleTableItemChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
        // showLeaderModal(checkLeader, pagination.current, sort);
        getData(record, pagination.current, sort)
    }
    const onItemCheck = (id) => {
      const filteredItems = itemInvoice.data.find(item => item._id === id);
  
      // Toggle checked state of the item
      if (checkedItems.includes(id)) {
          // If item is already checked, uncheck it by removing it from the list
          setCheckedItems(checkedItems.filter(itemId => itemId !== id));
      } else {
          // If item is not checked, check it by adding it to the list
          setCheckedItems([...checkedItems, id]);
      }
  
      // Update the selected item data when checked
      if (!checkedItems.includes(id)) {
          setItem({
              ...item,
              id: id,
              part_no_erp: filteredItems.part_no_erp,
              description: filteredItems.description,
              qty: filteredItems.qty,
              jenis_material_id: filteredItems.jenis_material,
              jenis_material_name: formattedJenisMaterial(filteredItems.jenis_material),
              part_status_id: filteredItems.part_status,
              part_status_name: formattedPartStatus(filteredItems.part_status),
              area_id: filteredItems.area_id,
              area_name: filteredItems.area_name,
              remarks: filteredItems.remarks,
          });
          setItemDisabled(false);
          setIsLeaderDisabled(false);
      } else {
          // If unchecked, you can clear the item or handle the logic accordingly
          setItem({
              ...item,
              id: '',
              part_no_erp: '',
              description: '',
              qty: '',
              jenis_material_id: '',
              jenis_material_name: '',
              part_status_id: '',
              part_status_name: '',
              area_id: '',
              area_name: '',
              remarks: '',
          });
          setItemDisabled(true);
          setIsLeaderDisabled(true);
        }
    };
    const handleChangeScanModal = (name, value) => {
      setError(null)
      setIsOkDisabled(false);
      // const minus = parseInt(item.balance) + parseInt(value);
      // console.log(minus, "minus");
      // if(name === 'qty_actual_receipt' && minus > item.total_qty){
      //   setError({
      //     ...error,
      //     qty_actual_receipt: "QTY actual receipt more than " + item.total_qty
      //   });
      //   console.log(error);
      //   setIsLeaderDisabled(true);
      // }else if(name === 'qty_actual_receipt' && minus < item.total_qty) {
      //     setIsLeaderDisabled(false);
      // }
      setItem({
          ...item,
          [name]: value
      })
    }
    const [jenisMaterial, setJenisMaterial] = useState([
      { label: 'Material', value: 'material' },
      { label: 'In Process', value: 'in_process' },
      { label: 'Finish Process', value: 'finish_process' },
    ]);
    const [partStatus, setPartStatus] = useState([
      { label: 'OK', value: 'ok' },
      { label: 'NG', value: 'ng' },
      { label: 'REWORK', value: 'rework' },
    ]);
    const handleChangeSelect = (name, value) => {
        setError(null)
        setItem({
            ...item,
            [`${name}_id`]: value.value,
            [`${name}_name`]: value.label
        })
    }
    const itemColumns = [
      {
          title: '',
          dataIndex: 'no',
          key: 'no',
          align:'center',
          width:50,
          render: (text, record) => (
          <Checkbox
              value={record._id}
              onChange={() => onItemCheck(record._id)}
              checked={checkedItems.includes(record._id)} // Control checked state
          />
          ),
      },
      {
          title: 'PART NO ERP',
          dataIndex: 'part_no_erp',
          key: 'part_no_erp',
          align:'center',
          width:200,
      },
      {
          title: 'DESCRIPTION',
          dataIndex: 'description',
          key: 'description',
          align:'center',
      },
      {
          title: 'QUANTITY',
          width:100,
          align:'center',
          dataIndex:'qty',
          key:'qty',
      },
      {
          title: 'PART STATUS',
          align:'center',
          dataIndex: 'part_status',
          key: 'part_status',
          render: (text, record, index) =>{
              return formattedPartStatus(record.part_status ?? 0);
          }
      },
      {
          title: 'JENIS MATERIAL',
          align:'center',
          dataIndex: 'jenis_material',
          key: 'jenis_material',
          render: (text, record, index) =>{
              return formattedJenisMaterial(record.jenis_material ?? 0);
          }
      },
      {
          title : 'STO BY',
          align : 'center',
          dataIndex:'sto_by',
          key:'sto_by',
      },
      {
          title : 'LEADER',
          align : 'center',
          dataIndex:'leader',
          key:'leader',
      }
  ];
  const getData = async (record, page, sort) => {
      setItemInvoice([]);
      if (!record || !record.part_no_erp) return; // Prevent fetching with invalid record
      setLoading(true);
      
      try {
          const response = await Axios.get(`${url}/warehouse-sto-get-history-detail`, {
              params: {
                  part_no_erp : record.part_no_erp,
                  perpage: 5,
                  page,
                  sort: sort.sort,
                  order: sort.order,
              },
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              }
          });
          setItemInvoice(response.data);
          setDataLoaded(true); // Set the flag to true after data is loaded
      } catch (err) {
          if (err.response) {  
              message.error(err.response.data.message);
              if (err.response.status === 401) {
                  sessionStorage.removeItem('token');
              }
          } else {
              message.error('Backend not yet started');
          }
      } finally {
          setLoading(false);
      }
  };
  useEffect(() => {
      const interval = setInterval(() => {
      setFormattedDate(moment().format('dddd DD MMM YYYY HH:mm:ss'));
      setDate(moment().format('YYYY/MM/DD HH:mm:ss'));
      }, 1000);

      return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);
  useEffect(() => {
      if (isModalVisible && record && record.part_no_erp && !dataLoaded) {
          getData(record, page, sort);
      }
  }, [isModalVisible, record, page, sort, dataLoaded]);

  // Reset the `dataLoaded` flag when the modal closes
  useEffect(() => {
      if (!isModalVisible) {
          setDataLoaded(false);
      }
  }, [isModalVisible]);
  return (
    <Modal
      title="Child Modal"
      visible={isModalVisible}
      onOk={okModal}
      onCancel={closeModal}
      okButtonProps={{ disabled: isLeaderDisabled }}  
      width={800} 
    >
       <Row>
          <Col xs={24}>
              <Table
              columns={itemColumns}
              dataSource={itemInvoice.data}
              rowKey={(data) => data._id} // Make sure this is an array
              pagination={paginationItem}
              loading={loading}
              onChange={handleTableItemChange}
              bordered
              scroll={{ x: 500 }}
              />
          </Col>  
      </Row>
      <Form 
        {...layout}
        name="control-hooks" style={{marginTop:'20px'}}>
        <Row type="flex" gutter={[10, 10]} justify="space-around">
            <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Receipt Date"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={date} disabled />
                </Form.Item>
            </Col>
            <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Part Number"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.part_no_erp} disabled />
                </Form.Item>
            </Col>
        </Row>
        <Row type="flex" gutter={[10, 10]} justify="space-around">
            <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Description"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.description} disabled />
                </Form.Item>
            </Col>
            <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Qty"
                    required
                >
                    <Input value={item.qty} onChange={(e) => handleChangeScanModal('qty', e.target.value)} disabled={itemDisabled}/>
                </Form.Item>
            </Col>
        </Row>
        <Row type="flex" gutter={[10, 10]} justify="space-around">
            <Col md={12} lg={12} xs={24}>
                {/* <Form.Item
                    label="Jenis Material"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.jenis_material} disabled />
                </Form.Item> */}
                <Form.Item
                    required
                    label="Jenis Material"
                    validateStatus={error && error.jenis_material ? 'error' : false }
                    help={error && error.jenis_material ? error.jenis_material[0] : false }
                >
                    <Select 
                        labelInValue
                        onChange={(value) => handleChangeSelect('jenis_material', value)}
                        // onFocus={() => getGroup('')}
                        showSearch 
                        // onSearch={(value) => getGroup(value)} 
                        filterOption={false}
                        value={{ key: item.jenis_material, label: item.jenis_material_name }}
                        disabled={itemDisabled}
                    >
                        {
                            jenisMaterial && jenisMaterial.map(group => {
                                return <Option value={group.value} key={group.value}>{group.label}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
            </Col>
            <Col md={12} lg={12} xs={24}>
                {/* <Form.Item
                    label="Part Status"
                    required
                >
                  <Input value={item.part_status} disabled />
                </Form.Item> */}
                <Form.Item
                    required
                    label="Part Status"
                    validateStatus={error && error.part_status ? 'error' : false }
                    help={error && error.part_status ? error.part_status[0] : false }
                >
                    <Select 
                        labelInValue
                        onChange={(value) => handleChangeSelect('part_status', value)}
                        // onFocus={() => getGroup('')}
                        showSearch 
                        // onSearch={(value) => getGroup(value)} 
                        filterOption={false}
                        value={{ key: item.part_status, label: item.part_status_name }}
                        disabled={itemDisabled}
                    >
                        {
                            partStatus && partStatus.map(group => {
                                return <Option value={group.value} key={group.value}>{group.label}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row type="flex" gutter={[10, 10]} justify="space-around">
            <Col md={12} lg={12} xs={24}>
                <Form.Item
                    required
                    label="Area"
                    validateStatus={error && error.area ? 'error' : false }
                    help={error && error.area ? error.area[0] : false }
                >
                    <Select 
                        labelInValue
                        onChange={(value) => handleChangeSelect('area', value)}
                        // onFocus={() => getGroup('')}
                        showSearch 
                        // onSearch={(value) => getGroup(value)} 
                        filterOption={false}
                        value={{ key: item.area_id, label: item.area_name }}
                        disabled={itemDisabled}
                    >
                        {
                            area && area.value.map(group => {
                                return <Option value={group.id} key={group.id}>{group.name}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
            </Col>
            <Col md={12} lg={12} xs={24}>
              <Form.Item
                  label="Remarks"
                  // validateStatus={error && error.part_number_internal ? 'error' : false }
                  // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
              >
                  <Input value={item.remarks} onChange={(e) => handleChangeScanModal('remarks', e.target.value)} disabled={itemDisabled}/>
              </Form.Item>
            </Col>
        </Row>
        <Row type="flex" gutter={[10, 10]} justify="space-around">

        </Row>
      </Form>
    </Modal>
  );
};

export default ChildComponent;