import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { url } from '../../config/global';

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function EditUser(props) {

    const [data, setData] = useState({
        username: '',
        full_name: '',
        password: '',
        password_confirmation: '',
        role_id: '',
        role_name: '',
        refer_id: '',
        refer_id: '',
        photo: null,
        refers: [],
        roles: []
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        Axios.get(`${url}/user/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setData({
                ...data,
                username: res.data.data.username,
                full_name: res.data.data.full_name,
                password: res.data.data.password,
                password_confirmation: res.data.data.password_confirmation,
                role_id: res.data.data.role_id,
                role_name: res.data.data.role_name,
                refer_id: (res.data.data.refer_id === 'null') ? '' : res.data.data.refer_id,
                refer_name: (res.data.data.refer_name === 'null') ? '' : res.data.data.refer_name,
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        })
    }

    const handleRemove = () => {
        setData({
            ...data,
            photo: null
        })
    }

    const handleBeforeUpload = (files) => {

        setData({
            ...data,
            photo: files
        })

        return false
    }

    const getExternalUser = async (keyword) => {

        await Axios.get(`${url}/user/list-external`, {
            params: {
                keyword
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setData({
                ...data,
                refers: res.data.data
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        })
    }

    const getRole = async (keyword) => {
        await Axios.get(`${url}/role/list`, {
            params: {
                keyword
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setData({
                ...data,
                roles: res.data.data
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        })
    }

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handleChangeSelect = (name, value) => {
        setError(null)
        setData({
            ...data,
            [`${name}_id`]: value.value,
            [`${name}_name`]: value.label
        })
    }

    const onSaveData = () => {

        setLoading(true)

        let fd = new FormData()

        fd.set('username', data.username)
        fd.set('full_name', data.full_name)
        if (data.password && data.password_confirmation){
            fd.set('password', data.password)
            fd.set('password_confirmation', data.password_confirmation)
        }
        fd.set('role_id', data.role_id)
        fd.set('role_name', data.role_name)
        fd.set('refer_id', data.refer_id)
        fd.set('refer_name', data.refer_name)
        fd.append('photo', data.photo)

        Axios.post(`${url}/user/${props.match.params.id}`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/user')
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            username: '',
            full_name: '',
            password: '',
            password_confirmation: '',
            role_id: '',
            refer_id: '',
            photo: null,
        })
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Edit User</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/user">User</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Edit User</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                            {...layout}
                            name="control-hooks"
                            onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Username"
                                    validateStatus={error && error.username ? 'error' : false }
                                    help={error && error.username ? error.username[0] : false }
                                >
                                    <Input value={data.username} onChange={(e) => handleChange('username', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Full Name"
                                    validateStatus={error && error.full_name ? 'error' : false }
                                    help={error && error.full_name ? error.full_name[0] : false }
                                >
                                    <Input value={data.full_name} onChange={(e) => handleChange('full_name', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    validateStatus={error && error.password ? 'error' : false }
                                    help={error && error.password ? error.password[0] : 'Blank password if you don\'t want to change' }
                                >
                                    <Input.Password onChange={(e) => handleChange('password', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    label="Retype Password"
                                >
                                    <Input.Password onChange={(e) => handleChange('password_confirmation', e.target.value)} />
                                </Form.Item>
                            
                                <Form.Item
                                    label="Role"
                                >
                                    <Select 
                                        labelInValue
                                        value={{ key: data.role_id, label: data.role_name }}
                                        onChange={(value) => handleChangeSelect('role', value)}
                                        onFocus={() => getRole('')}
                                        showSearch 
                                        onSearch={(value) => getRole(value)} 
                                        filterOption={false}
                                    >
                                        {
                                            data.roles && data.roles.map(role => {
                                                return <Option value={role._id} key={role._id}>{role.name}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Refer To"
                                >
                                    <Select labelInValue
                                        value={{ key: data.refer_id, label: data.refer_name }} onChange={(value) => handleChangeSelect('refer', value)} onFocus={() => getExternalUser('')} showSearch onSearch={(value) => getExternalUser(value)} filterOption={false}>
                                        {
                                            data.refers && data.refers.map(refer => {
                                                return <Option key={refer.username} value={refer.username}>{refer.full_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="photo"
                                    label="Photo"
                                >
                                <Fragment>
                                    <Upload onRemove={handleRemove} beforeUpload={handleBeforeUpload} fileList={[]}>
                                        <Button>
                                            Upload <UploadOutlined />
                                        </Button>
                                    </Upload>
                                    {
                                        data.photo && <List>
                                            <List.Item actions={[<Button onClick={handleRemove} type="danger" ghost><DeleteOutlined /></Button>]}>
                                                <List.Item.Meta description={data.photo.name} />
                                            </List.Item>
                                        </List>
                                    }
                                </Fragment>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                    Reset
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
                
            </Content>
        </Fragment>
    )
}

export default EditUser