import React, {useState, useEffect} from 'react'
import { Menu, Badge, Avatar, Space, Dropdown, Tabs, List, Button, Input, Popover, message } from 'antd'
import { MenuFoldOutlined, SearchOutlined, QuestionCircleFilled, BellOutlined, UserOutlined, SettingOutlined, LogoutOutlined, MenuUnfoldOutlined, ReloadOutlined } from '@ant-design/icons'
import './Navbar.less'
import { Link } from 'react-router-dom'
import Axios from 'axios';
import {url} from '../config/global'
import NotificationBell from './NotificationBell'

const { TabPane } = Tabs

const data = [
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    }
]

function Navbar(props) {
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState(false)
    const [notifications, setNotifications] = useState([]);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleNotification = () => {
        setVisible(!visible)
    }

    const handleSeeMore = () => {
        setVisible(false)
    }

    const handleLogout = () => {
        console.log('terclick')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('fullname')
        sessionStorage.removeItem('refername')
        sessionStorage.removeItem('role_name')
        sessionStorage.removeItem('avatar')
        sessionStorage.removeItem('permissions')
        sessionStorage.removeItem('redirect')

        return props.history.push('/login') 
    }

    const handleToggleSearch = (value = false) => {
        console.log(value)
        setSearch(value)
    }

    const fetchNotifications = async () => {
        setPopoverVisible(false);
        await Axios.get(`${url}/notifications`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            console.log(res.data);
            setNotifications(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false);
        })
    };

    useEffect(() => {
        fetchNotifications();
    }, []); // Empty dependency array to run only once
    
    // Filter unread notifications
    const unreadCount = notifications.total
    console.log(unreadCount, "count unread");

    // Mark a single notification as read
    const markAsRead = async (id) => {
        setLoading(true);
        setPopoverVisible(false);
        await Axios.get(`${url}/notifications/read/${id}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            console.log(res.data);
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            fetchNotifications();
            setLoading(false);
        })
    };

    // Mark all as read
    const markAllAsRead = async () => {
        setLoading(true);
        setPopoverVisible(false);
        await Axios.get(`${url}/notifications/read-all`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
          console.log(res.data);
          message.success(res.data.message)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            fetchNotifications();
            setLoading(false);
        })
    };

    
    // Toggle popover visibility
    const handlePopoverVisibility = () => {
        setPopoverVisible(!popoverVisible);
    };


    return (
        <div className="navbar">
            <div>
                { props.collapsed ? <MenuUnfoldOutlined onClick={props.onToggleCollapsed} /> : <MenuFoldOutlined onClick={props.onToggleCollapsed} /> }
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '30px', paddingRight: 16}}>
                {/* Notification Bell with Badge */}
                <Popover content={
                    <NotificationBell notifications={notifications} markAsRead={markAsRead} markAllAsRead={markAllAsRead} />
                } title="Notifications" trigger="click"  visible={popoverVisible}
                onVisibleChange={handlePopoverVisibility}>
                    <Badge count={unreadCount} offset={[10, 0]}>
                        <BellOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                    </Badge>
                </Popover>
                <Menu mode="horizontal" className="menu-header">
                    
                    <Menu.Item key="profile">
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item key="3">
                                    <span onClick={handleLogout}> 
                                        <LogoutOutlined />Logout
                                    </span>
                                </Menu.Item>
                            </Menu>
                        } trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <Space>
                                    {
                                        sessionStorage.getItem('avatar') ? (
                                            <Avatar size="small" src={sessionStorage.getItem('avatar')} />
                                        ) : (
                                            <Avatar size="small" icon={<UserOutlined />} />
                                        )
                                    }
                                    { !props.screens.xs && <span> { sessionStorage.getItem('fullname') } </span> }
                                </Space>
                            </a>
                        </Dropdown>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    )
}

export default Navbar
