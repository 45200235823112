import React from 'react';
import { Badge, Tooltip, List, Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import moment from 'moment';

const NotificationBell = ({ notifications, markAsRead, markAllAsRead }) => (
  <div style={{ width: 300, maxHeight: '200px', overflowY: 'auto', overflowX: 'auto' }}>
    <List
    itemLayout="horizontal"
    dataSource={notifications.data}
    renderItem={(notification) => (
        <List.Item style={{
            backgroundColor: notification.read ? 'white' : '#e6f7ff', // Light blue background for unread notifications
            padding: '10px',
            borderBottom: '1px solid #f0f0f0',
            cursor: 'pointer',
          }}
          onClick={() => markAsRead(notification._id)} // Mark as read on click
        >
        <List.Item.Meta
            // avatar={<Avatar icon={<BellOutlined />} />}
            title={notification.title}
            description={
              <>
                <div>{notification.message}</div>
                <div style={{ fontSize: '12px', color: '#888', textAlign: 'right', marginTop: '5px' }}>
                  {notification.date}
                </div>
              </>
            }
        />
        </List.Item>
    )}
    />
    {notifications.total > 0 && (
      <Button type="primary" onClick={markAllAsRead} block>
      Mark all as read
      </Button>
    )}
  </div>
);

export default NotificationBell;