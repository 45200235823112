import React, {useState, useEffect, Fragment} from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message,Select, Space, Form, Badge, Checkbox} from 'antd';
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, UploadOutlined, CaretDownOutlined, QrcodeOutlined, CameraOutlined, RedoOutlined, BarcodeOutlined} from '@ant-design/icons'
import QrReader from 'react-qr-reader';
import moment from 'moment';
import Axios from 'axios';
import BarcodeScanner from './BarcodeScanner';
import {url} from '../../../config/global'
const layout = {
    labelCol: {
        lg: 10,
        md: 10
    },
    wrapperCol: {
        lg: 14,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}
const LeaderCheck = ({record, visible, onOk, onCancel, item, setItem}) => {
    const [itemInvoice, setItemInvoice] = useState([]) // Use this for the table data
    // const [item, setItem] = useState({
    //     id: '',
    //     no_invoice: '',
    //     po_number: '',
    //     part_number: '',
    //     part_number_external: '',
    //     sourcing: '',
    //     description: '',
    //     total_qty: '',
    //     satuan: '',
    //     qty_actual_receipt: '',
    //     tgl_receipt: '',
    //     unique_code: '',
    //     label_part: '',
    //     label_kemasan: '',
    //     remarks: '',
    // })
    const [success, setSuccess] = useState(true);
    const [messageQr, setMessageQr] = useState('');
    const [loading, setLoading] = useState(false)
    const [itemDisabled, setItemDisabled] = useState(true);
    const [isLeaderDisabled, setIsLeaderDisabled] = useState(true);
    const [isUniqueCodeModalVisible, setIsUniqisUniqueCodeModalVisible] = useState(false);
    const [modalBarcode, setModalBarcode] = useState(false);
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })
    const [error, setError] = useState({
         qty_actual_receipt: ''
    })
    const [formattedDate, setFormattedDate] = useState(moment().format('dddd DD MMM YYYY HH:mm:ss'));
    const [date, setDate] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
    const [isOkDisabled, setIsOkDisabled] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false); // Flag to track if data is already loaded
    const [checkedItems, setCheckedItems] = useState([]);
      // Fungsi untuk check item
    const onItemCheck = (id) => {
        const filteredItems = itemInvoice.data.find(item => item._id === id);
    
        // Toggle checked state of the item
        if (checkedItems.includes(id)) {
            // If item is already checked, uncheck it by removing it from the list
            setCheckedItems(checkedItems.filter(itemId => itemId !== id));
        } else {
            // If item is not checked, check it by adding it to the list
            setCheckedItems([...checkedItems, id]);
        }
    
        // Update the selected item data when checked
        if (!checkedItems.includes(id)) {
            setItem({
                ...item,
                id: id,
                no_invoice: filteredItems.no_invoice,
                po_number: filteredItems.po_number,
                part_number: filteredItems.part_number,
                part_number_external: filteredItems.part_number_external,
                description: filteredItems.description,
                balance: parseInt(filteredItems.balance) - parseInt(filteredItems.qty),
                total_qty: filteredItems.total_qty,
                satuan: filteredItems.satuan,
                sourcing: filteredItems.sourcing,
                qty_actual_receipt: filteredItems.qty,
                unique_code: filteredItems.unique_code,
                label_part: '',
                label_kemasan: '',
                remarks: filteredItems.remarks,
            });
            setItemDisabled(false);
            setIsLeaderDisabled(false);
        } else {
            // If unchecked, you can clear the item or handle the logic accordingly
            setItem({
                ...item,
                id: '',
                no_invoice: '',
                po_number: '',
                part_number: '',
                part_number_external: '',
                description: '',
                balance: '',
                total_qty: '',
                satuan: '',
                sourcing: '',
                qty_actual_receipt: '',
                unique_code: '',
                label_part: '',
                label_kemasan: '',
                remarks: '',
            });
            setItemDisabled(true);
            setIsLeaderDisabled(true);
        }
    };
    const paginationItem = {
        pageSize: 10,
        current: page,
        total: itemInvoice.total,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
      };
    
    const handleTableItemChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
        // showLeaderModal(checkLeader, pagination.current, sort);
        getData(record, pagination.current, sort)
    }
    const handleChangeScanModal = (name, value) => {
        setError(null)
        setIsOkDisabled(false);
        const minus = parseInt(item.balance) + parseInt(value);
        console.log(minus, "minus");
        if(name === 'qty_actual_receipt' && minus > 0){
          setError({
            ...error,
            qty_actual_receipt: "QTY actual receipt more than " + item.total_qty
          });
          console.log(error);
          setIsLeaderDisabled(true);
        }else if(name === 'qty_actual_receipt' && minus < 0) {
            setIsLeaderDisabled(false);
        }
        setItem({
            ...item,
            [name]: value
        })
    }
    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };
    const showBarcodeModal = () => {
        setModalBarcode(true);
        setSuccess(true);
    };
    const showUniqueCodeModal = () => {
        setIsUniqisUniqueCodeModalVisible(true);
        setSuccess(true);
    };
    const handleCancelQrUniqueCode = () => {
        setIsUniqisUniqueCodeModalVisible(false);
        setSuccess(false);  // You can stop QR scanning here
    };

    const handleCancelBarcodeModal = () => {
        setModalBarcode(false);
        setSuccess(false);  // You can stop QR scanning here
    };

    const handleRetry = () => {
        setSuccess(true)
    }
    
    const handleError = (err) => {
        console.log(err)
    }

    const handleScanUniqueCode = (value) => {
        if (value !== null) {
            console.log(value);
            // setScanMaterial(value);
            setSuccess(false);
            setMessageQr('Scan Qr Successfully');
            setIsUniqisUniqueCodeModalVisible(false);
            handleChangeScanModal('unique_code', value);
            // handlePostMaterial(value);
        }
    }

    const handleScanBarCode = (value) => {
        if (value !== null) {
            console.log(value);
            // setScanMaterial(value);
            setSuccess(false);
            setMessageQr('Scan Barcode Successfully');
            setModalBarcode(false);
            handleChangeScanModal('unique_code', value);
            // handlePostMaterial(value);
        }
    }

    const itemColumns = [
        {
            title: '',
            dataIndex: 'no',
            key: 'no',
            align:'center',
            width:50,
            render: (text, record) => (
            <Checkbox
                value={record._id}
                onChange={() => onItemCheck(record._id)}
                checked={checkedItems.includes(record._id)} // Control checked state
            />
            ),
        },
        {
            title: 'PART NUMBER',
            dataIndex: 'part_number',
            key: 'part_number',
            align:'center',
            width:200,
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
            align:'center',
        },
        {
            title: 'QUANTITY',
            width:100,
            align:'center',
            dataIndex:'qty',
            key:'qty',
        },
        {
            title: 'UNIQUE CODE',
            width:100,
            align:'center',
            dataIndex:'unique_code',
            key:'unique_code',
        },
        {
            title: 'SATUAN',
            align:'center',
            dataIndex:'satuan',
            key:'satuan',
        },
        {
            title : 'CHECK ITEM',
            align:'center',
            children: [
            {
                title: 'LABEL PART',
                align: 'center',
                // dataIndex: 'label_part',
                // key:'label_part',
                render: (text,record,index) => {
                return <Badge count={record.label_part == '1' ? 'OK' : 'NG'} style={{
                    backgroundColor: record.label_part == '1' ? '#52c41a' : 'red',
                }} />;
                }
            },
            {
                title: 'KEMASAN',
                align: 'center',
                // dataIndex: 'kemasan',
                // key:'kemasan',
                render: (text,record,index) => {
                return <Badge count={record.label_kemasan == '1' ? 'OK' : 'NG'} style={{
                    backgroundColor: record.label_kemasan == '1' ? '#52c41a' : 'red',
                }} />;
                }
            },
            ]
        },
        {
            title : 'RECEIVE BY',
            align : 'center',
            dataIndex:'created_by',
            key:'created_by',
        }
    ];
    const getData = async (record, page, sort) => {
        setItemInvoice([]);
        if (!record || !record.part_number) return; // Prevent fetching with invalid record
        setLoading(true);
        
        try {
            const response = await Axios.get(`${url}/warehouse-checksheets/check-leader`, {
                params: {
                    part_number: record.part_number,
                    no_invoice: record.number,
                    po_number: record.po_number,
                    perpage: 10,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            });
            setItemInvoice(response.data);
            setDataLoaded(true); // Set the flag to true after data is loaded
        } catch (err) {
            if (err.response) {  
                message.error(err.response.data.message);
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token');
                }
            } else {
                message.error('Backend not yet started');
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
        setFormattedDate(moment().format('dddd DD MMM YYYY HH:mm:ss'));
        setDate(moment().format('YYYY/MM/DD HH:mm:ss'));
        }, 1000);

        return () => clearInterval(interval); // Clear the interval on component unmount
    }, []);
    useEffect(() => {
        if (visible && record && record.part_number && !dataLoaded) {
            getData(record, page, sort);
        }
    }, [visible, record, page, sort, dataLoaded]);

    // Reset the `dataLoaded` flag when the modal closes
    useEffect(() => {
        if (!visible) {
            setDataLoaded(false);
        }
    }, [visible]);
    return (
        <Fragment>
            <Modal
                title="Check Item By Leader"
                visible={visible}
                onOk={onOk}
                onCancel={onCancel}
                width={800} 
                okButtonProps={{ disabled: isLeaderDisabled }}  
                > 
                <Row>
                    <Col xs={24}>
                        <Table
                        columns={itemColumns}
                        dataSource={itemInvoice.data}
                        rowKey={(data) => data._id} // Make sure this is an array
                        pagination={paginationItem}
                        loading={loading}
                        onChange={handleTableItemChange}
                        bordered
                        scroll={{ x: 500 }}
                        />
                    </Col>  
                </Row>
                <Form 
                {...layout}
                name="control-hooks" style={{marginTop:'20px;'}}>
                <Row type="flex" gutter={[10, 10]} justify="space-around">
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Part Number"
                            // validateStatus={error && error.part_number_internal ? 'error' : false }
                            // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                        >
                            <Input value={item.part_number} disabled />
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Description"
                            // validateStatus={error && error.part_number_internal ? 'error' : false }
                            // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                        >
                            <Input value={item.description} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row type="flex" gutter={[10, 10]} justify="space-around">
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Total Qty"
                            // validateStatus={error && error.part_number_internal ? 'error' : false }
                            // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                        >
                            <Input value={formattedNumber(item.total_qty)} disabled/>
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Satuan"
                            // validateStatus={error && error.part_number_internal ? 'error' : false }
                            // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                        >
                            <Input value={item.satuan} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row type="flex" gutter={[10, 10]} justify="space-around">
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Qty Actual Receipt"
                            required
                            validateStatus={error && error.qty_actual_receipt ? 'error' : false }
                            help={error && error.qty_actual_receipt ? error.qty_actual_receipt : false }
                        >
                            <Input value={item.qty_actual_receipt} onChange={(e) => handleChangeScanModal('qty_actual_receipt', e.target.value)} disabled={itemDisabled}/>
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Receipt Date"
                            // validateStatus={error && error.part_number_internal ? 'error' : false }
                            // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                        >
                            <Input value={date} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row type="flex" gutter={[10, 10]} justify="space-around">
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Unique Code"
                        >
                        <Row type="flex" gutter={[10, 0]} justify="space-around">
                            <Col md={20} lg={20} xs={20}>
                                <Input value={item.unique_code} onChange={(e) => handleChangeScanModal('unique_code', e.target.value)} disabled={itemDisabled}/>
                            </Col>
                            <Col span={4}>
                                <Button onClick={showUniqueCodeModal} disabled={itemDisabled}>
                                <QrcodeOutlined />
                                </Button>
                                <Modal
                                title="Scan QR Unique Code"
                                visible={isUniqueCodeModalVisible}
                                onCancel={handleCancelQrUniqueCode}
                                footer={null}
                                >
                                {success ? (
                                    <QrReader
                                    delay={100}
                                    onError={handleError}
                                    onScan={handleScanUniqueCode}
                                    className="qrcode"
                                    showViewFinder={false}
                                    style={{ width: '200px', height: '200px' }}
                                    />
                                ) : (
                                    <div className="qrcode-fail">
                                    <p>{messageQr}</p>
                                    <p>Retry</p>
                                    <Button type="link" size="large" onClick={handleRetry}>
                                        <RedoOutlined />
                                    </Button>
                                    </div>
                                )}
                                </Modal>
                            </Col>
                        </Row>
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Remarks"
                            // validateStatus={error && error.part_number_internal ? 'error' : false }
                            // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                        >
                            <Input value={item.remarks} onChange={(e) => handleChangeScanModal('remarks', e.target.value)} disabled={itemDisabled}/>
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row type="flex" gutter={[10, 10]} justify="space-around">
                    <Col md={12} lg={12} xs={24} style={{marginRight:10}}>
                        <Form.Item
                        label="Unique Code"
                        style={{ textAlign: 'left' }}
                        >
                        <Input 
                            value={item.unique_code} 
                            onChange={(e) => handleChangeScanModal('unique_code', e.target.value)} disabled={itemDisabled}
                        />
                        </Form.Item>
                    </Col>
                    <Col md={2} lg={2} xs={2}>
                        <Button onClick={showUniqueCodeModal} disabled={itemDisabled}>
                        <QrcodeOutlined />
                        </Button>
                        <Modal
                        title="Scan QR Unique Code"
                        visible={isUniqueCodeModalVisible}
                        onCancel={handleCancelQrUniqueCode}
                        footer={null}
                        >
                        {success ? (
                            <QrReader
                            delay={100}
                            onError={handleError}
                            onScan={handleScanUniqueCode}
                            className="qrcode"
                            showViewFinder={false}
                            style={{ width: '200px', height: '200px' }}
                            />
                        ) : (
                            <div className="qrcode-fail">
                            <p>{messageQr}</p>
                            <p>Retry</p>
                            <Button type="link" size="large" onClick={handleRetry}>
                                <RedoOutlined />
                            </Button>
                            </div>
                        )}
                        </Modal>
                    </Col>
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item
                            label="Remarks"
                            // validateStatus={error && error.part_number_internal ? 'error' : false }
                            // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                        >
                            <Input value={item.remarks} 
                            onChange={(e) => handleChangeScanModal('remarks', e.target.value)} disabled={itemDisabled} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                    <Button onClick={showBarcodeModal} disabled={itemDisabled}>
                        <BarcodeOutlined />
                    </Button>
                    <Modal
                        title="Scan Barcode Unique Code"
                        visible={modalBarcode}
                        onCancel={handleCancelBarcodeModal}
                        footer={null}
                    >
                        {success ? (
                        <div>
                            <BarcodeScanner show={modalBarcode} setShow={setSuccess} setValue={handleScanBarCode} />
                        </div>
                        ) : (
                        <div className="qrcode-fail">
                            <p>{messageQr}</p>
                            <p>Retry</p>
                            <Button type="link" size="large" onClick={handleRetry}>
                            <RedoOutlined />
                            </Button>
                        </div>
                        )}
                    </Modal>
                    </Col>
                </Row> */}
                <Row type="flex" gutter={[10, 10]} justify="space-around">
                    <Col xs={24} lg={12} md={12}>
                        <Form.Item label="Label Part"
                        >
                        {item.label_part ? (
                            <Badge count={item.label_part == '1' ? 'OK' : 'NG'} style={{
                            backgroundColor: item.label_part == '1' ? '#52c41a' : 'red',
                            }} />
                        ) :  (
                        <>
                        <Button
                            type="primary"
                            style={{ backgroundColor: itemDisabled ? '#f5f5f5' : '#52c41a', borderColor: itemDisabled ? '#f5f5f5' : '#d9d9d9', marginRight: '20px' }}
                            onClick={() => {
                                // Your click event logic here
                                handleChangeScanModal('label_part', '1');
                            }}
                            disabled={itemDisabled}>
                            √
                        </Button>
                        <Button type="primary" danger onClick={() => {
                            // Your click event logic here
                            handleChangeScanModal('label_part', '0');
                            }}
                            disabled={itemDisabled}>
                            X
                        </Button>
                        </>
                        )}
                        </Form.Item>
                    </Col>          
                    <Col md={12} lg={12} xs={24}>
                        <Form.Item label="Kemasan"
                        >
                        {item.label_kemasan ? (
                            <Badge count={item.label_kemasan == '1' ? 'OK' : 'NG'} style={{
                            backgroundColor: item.label_kemasan == '1' ? '#52c41a' : 'red',
                            }} />
                        ) :  (
                        <>
                        <Button
                            type="primary"
                            style={{ backgroundColor: itemDisabled ? '#f5f5f5' : '#52c41a', borderColor: itemDisabled ? '#f5f5f5' : '#d9d9d9', marginRight: '20px' }}
                            onClick={() => {
                                // Your click event logic here
                                handleChangeScanModal('label_kemasan', '1');
                            }}
                            disabled={itemDisabled}>
                            √
                        </Button>
                        <Button type="primary" danger onClick={() => {
                            // Your click event logic here
                            handleChangeScanModal('label_kemasan', '0');
                            }}
                            disabled={itemDisabled}>
                            X
                        </Button>
                        </>
                        )}
                        </Form.Item>
                    </Col>
                </Row>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default LeaderCheck