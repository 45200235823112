import React, { Fragment, useState } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, QRCode, Typography, message,Space,Modal,Table ,Divider} from 'antd';
import { Link, Redirect,useHistory } from 'react-router-dom';

import Axios from 'axios';
import { url } from '../../config/global';
import { QrcodeOutlined } from "@ant-design/icons";
const { Title } = Typography
const { Content } = Layout

function Generate() {
  //const navigate = useNavigate()
  const history = useHistory()

  const [visibleTable, setVisibleTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [countSelected, setCountSelected] = useState(0);
  const [dataSelected, setDataSelected] = useState([]);
  if (!sessionStorage.getItem('token')) {
    return <Redirect to="/login" />
  }

    const modal = Modal;
    const [form] = Form.useForm();
    const [form_scan] = Form.useForm();

    
    const submitGenerateHandler = () => {
      
         form.validateFields().then((formData) => {
          if(formData.fieldStock % formData.fieldSplit ==0){
            Axios.post(
                `${url}/label-generate2`,formData,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
              if(res.data.status){
                message.success(res.data.message);
                form.resetFields();
                modal.destroyAll();
                form_scan.resetFields();
                setVisibleTable(true)
                setDataTable(res.data.payload)
              }else{
                message.error(res.data.message);
              }

            })
            .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                    } else {
                        message.error(err.message);
                    }
                });
          }else{
            message.error("Data Invalid, Pastikan Data yang di split hasilnya bernilai genap");
          }

         })

    };
    
    const onFinishFailed = () => {
      message.error('Submit failed!');
    };

    const onReset = () => {
        form.resetFields(['fieldSplit']);
      };

    const onClose = () => {
        modal.destroyAll()
        form.resetFields(['fieldSplit']);
      };


    const scanHandler= (val) => {
      setVisibleTable(false)
      if(val!=''){
        Axios.post(
          `${url}/label-scan2`,
          {
            part_number: val,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            //me redirect ke page lain 

            const data =res.data.data[0]
            modal.confirm({
                  title:  "Generate Label Material",
                  content: (
                    <div className="mb4">
                      <h5>Part Number : {data.part_number}</h5>
                      <p>{data.description}</p>
                      <p>Stock {parseInt(data.qty).toLocaleString()}</p>
                      <Form
                            form={form}
                            layout="vertical"
                            onFinish={submitGenerateHandler}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            initialValues={{
                                fieldId: data._id,
                                fieldStock: parseInt(data.qty),
                            }}
                            >
                            <Form.Item
                                name="fieldSplit"
                                label="Count Split"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input count split!',
                                    }
                                ]}
                            >
                                <Input type='number' min={1} max={parseInt(data.qty)} placeholder="input count split" />
                            </Form.Item>
                            <Form.Item
                                name="fieldId"
                                style={{ display: 'none'} }
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Space>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button htmlType="reset" onClick={onReset}>
                                    Reset
                                </Button>
                                <Button color="default" onClick={()=> onClose() }>
                                    Close
                                </Button>
                                </Space>
                            </Form.Item>
                            <Form.Item
                                name="fieldStock"
                                style={{ display: 'none'} }
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </div>
                  ),
                  autoFocusButton: null,
                  okButtonProps:{
                    hidden: true,
                  },
                  cancelButtonProps: {
                    hidden: true,
                  },
                });
          } else {
            message.error("Data not found!");
          }
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error(err.message);
          }
        });
      }
    };

    const printHandler =()=>{
      Axios.post(
        `${url}/print-label2`,
        {
          payload: dataSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          history.push('/printLabelMaterial', { state: { dataSelected } })
        }else{
          message.error("Prosess Print Failed!");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error(err.message);
        }
      });
    
    }
    const columns = [
      {
        title: 'Qrcode',
        dataIndex: 'qrcode',
        key: 'qrcode',
        render: text => <a>{text}</a>,
      },
      {
        title: 'Sequance',
        dataIndex: 'sequance',
        key: 'sequance',
      },
      {
        title: 'Number',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Quantity',
        key: 'qty',
        render: (record) =>  record.qty?.toLocaleString() + " " + record.uom
        
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setCountSelected(selectedRows)
        setDataSelected(selectedRows)
      }
    };
    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                      <Title level={4}>Generate Label Material</Title>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col span={24} >
                        <Card className="body-data">
                          <Form
                            form={form_scan}
                            layout="vertical"
                          >
                            <Form.Item  name="scan_partnumber">
                              <Input 
                                addonBefore="Qrcode"
                                addonAfter={<QrcodeOutlined/>}
                                placeholder="Scan Here ..."
                                allowClear
                                autoFocus
                                onPressEnter={(e) =>
                                  scanHandler(e.target.value)
                                }
                                onChange={(e) =>
                                    scanHandler(e.target.value)
                                }
                              />
                            </Form.Item>
                          </Form>
                        </Card>
                    </Col>
                </Row>
                
                {visibleTable? 
                (
                <Row>
                  <Col xs={24}>
                    <Table
                      columns={columns}
                      dataSource={dataTable}
                      bordered
                      rowSelection={rowSelection}
                      title={() => 
                        <Row>
                          <Col span={4}>
                            <Button type="primary" onClick={printHandler} disabled={countSelected?.length > 0? false : true}>
                              Print  {countSelected?.length > 0 ? `${countSelected?.length} Label` : ''}
                            </Button>
                          </Col>
                          <Col span={16}>
                            <center><span>Result Generate Material Label</span></center>
                          </Col>
                          <Col span={4}></Col>
                        </Row>
                        }
                    />
                  </Col>
                </Row>
                ) : <></> }
            </Content>
        </Fragment>
    )
}

export default Generate