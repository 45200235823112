import React, { useState, useEffect, Fragment } from 'react'
import { Layout, Grid, Col, Card, Breadcrumb, Row, Typography } from 'antd';
import { CopyrightCircleOutlined } from '@ant-design/icons';
import './CheckSheetLayout.less';
import Sidebar from './Sidebar';
import NavbarCheckSheet from './NavbarCheckSheet';
const { Header, Footer, Sider } = Layout;
const { useBreakpoint } = Grid
const { Title, Paragraph } = Typography

function CheckSheetLayout(props) {

    const [collapsed, setCollapsed] = useState(false)
    const screens = useBreakpoint();

    const handleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    useEffect(() => {
        // props.screens.xs && setCollapsed(false)
        const width = window.innerWidth
        width <= 500 && setCollapsed(false)
        
    }, [])

    const refresh = () => {
       const path = props.history.location.pathname
       window.location = path
    }

    return (
        <Layout>
            <Sider 
                breakpoint="lg"
                onBreakpoint={broken => {
                    setCollapsed(broken)
                }}
                className={`sidebar ${!collapsed ? 'sidebar-none' : 'sidebar-uncollapsed'}`}
                trigger={null} 
                collapsed={!collapsed}>
                <Sidebar {...props} collapsed={!collapsed} />
            </Sider>
            <Layout>
            <Header className="header">
                <NavbarCheckSheet {...props} refresh={refresh} collapsed={collapsed} screens={screens} onToggleCollapsed={handleCollapsed} />
            </Header>
                <Fragment>
                    {props.children}
                </Fragment>
            <Footer style={{bottom:0, width:'100%'}}><CopyrightCircleOutlined /> 2020 Sanoh ERP</Footer>
            </Layout>
      </Layout>   
    )
}

export default CheckSheetLayout
