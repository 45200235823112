import React, {useState, useEffect} from 'react';
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message,Select, Space, Form, Badge, Checkbox} from 'antd';
import moment from 'moment';
import Axios from 'axios';
import {url} from '../../../config/global'
const { Title } = Typography
const { Option } = Select;
const { Content } = Layout
const layout = {
  labelCol: {
      lg: 10,
      md: 10
  },
  wrapperCol: {
      lg: 14,
      md: 14
  },
}
const tailLayout = {
  wrapperCol: {
      offset: 8,
      span: 16,
  },
}

const EditBeginning = ({ record, isModalVisible, okModal, closeModal, item, setItem }) => {
    console.log(item, "item")
    const [formattedDate, setFormattedDate] = useState(moment().format('dddd DD MMM YYYY HH:mm:ss'));
    const [date, setDate] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
    useEffect(() => {
        const interval = setInterval(() => {
        setFormattedDate(moment().format('dddd DD MMM YYYY HH:mm:ss'));
        setDate(moment().format('YYYY/MM/DD HH:mm:ss'));
        }, 1000);
  
        return () => clearInterval(interval); // Clear the interval on component unmount
    }, []);
    const handleChangeScanModal = (name, value) => {
        setItem({
            ...item,
            [name]: value
        })
      }
    return (
        <Modal
        title="Edit Beginning"
        visible={isModalVisible}
        onOk={okModal}
        onCancel={closeModal}
        width={800}
        >
            <Form 
                {...layout}
                name="control-hooks" style={{marginTop:'20px'}}>
                    <Row type="flex" gutter={[10, 10]} justify="space-around">
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Receipt Date"
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={date} disabled />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Part No ERP"
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={item.part_no_erp} disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" gutter={[10, 10]} justify="space-around">
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Description"
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={item.description} disabled />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Beginning"
                                required
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={item.qty} onChange={(e) => handleChangeScanModal('qty', e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" gutter={[10, 10]} justify="flex-start">
                        <Col md={12} lg={12} xs={24}>
                            <Form.Item
                                label="Remarks"
                                // validateStatus={error && error.part_number_internal ? 'error' : false }
                                // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                            >
                                <Input value={item.remarks} onChange={(e) => handleChangeScanModal('remarks', e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Modal>
    );
};

export default EditBeginning;